import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Subtitles,
    Chat,
    pubnub,
    TextToSpeech,
    SpeechToText,
    MainWrapper,
    useStateValue,
    Select,
} from 'wwtc-sdk';
import {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
    endpointDetectLanguage,
    signedEndpoint,
} from '../constants/connection';
import {
    Download,
    Movie,
    Desktop,
    Times,
    Users,
    User,
    Share,
    LanguageDetection,
} from './svg/icons';
import { handlePreRequestSubtitles } from './utils';
import buildSubtitlesFile from '../utils/subtitles';
import specialLanguages from '../constants/specialLanguages';

function Main() {
    // This values come from Login components
    // Also could set up constants values
    // This values are required like props of the MainWrapper
    const history = useHistory();
    const [isModerator, setIsModerator] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalClipboard, setModalClipboard] = useState(false);
    const [modalLanguageDetection, setModalLanguageDetection] = useState(false);

    const { userName, language, gender, room, userInvite, roomType, apiToken, languages } =
        history.location.state;

    useEffect(() => {
        setIsModerator(!userInvite);

        pubnub.addListener({
            signal: function (s) {
                if (s.message === 'logout_admin' && !isModerator) {
                    pubnub.unsubscribeAll();
                    window.location.replace(window.location.origin);
                }
                if (s.message === 'clean_history_content' && !!userInvite) {
                    // clean subtitles content
                    document.getElementsByClassName('fa-trash-alt')[0].parentNode.click();
                }
            },
        });
        pubnub.setState({
            state: { role: 'moderator' },
            channels: [room],
        });

        return () => {};
    }, []);

    return (
        <MainWrapper
            autologin={false}
            gender={gender}
            language={language}
            roomName={room}
            userName={userName}
        >
            <Header
                pubnub={pubnub}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                setModalClipboard={setModalClipboard}
                isModerator={isModerator}
                roomType={roomType}
                setModalLanguageDetection={setModalLanguageDetection}
            />
            <WrapperComponents
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                modalClipboard={modalClipboard}
                setModalClipboard={setModalClipboard}
                isModerator={isModerator}
                setIsModerator={setIsModerator}
                userInvite={userInvite}
                apiToken={apiToken}
                setModalLanguageDetection={setModalLanguageDetection}
                modalLanguageDetection={modalLanguageDetection}
                languages={languages}
            />
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const {
        pubnub,
        initName,
        setModalVisible,
        setModalClipboard,
        conferenceName,
        isModerator,
        setModalLanguageDetection,
    } = props;

    const handleLogout = async () => {
        if (pubnub) {
            if (isModerator) {
                await pubnub.signal({
                    message: 'logout_admin',
                    channel: conferenceName,
                });
            }
            pubnub.unsubscribeAll();
            window.location.replace(window.location.origin);
        } else {
            window.location.replace(window.location.origin);
        }
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img src={`${window.logoHeader}`} alt="WWTC" />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span>NOW Video Conferencing</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span
                        className="lang-detection-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Languade Detection"
                        data-container="body"
                        onClick={() => setModalLanguageDetection(true)}
                    >
                        <LanguageDetection />
                    </span>
                    &nbsp;
                    {isModerator && (
                        <span
                            className="share-btn"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Share room"
                            data-container="body"
                            onClick={() => setModalClipboard(true)}
                        >
                            <Share />
                        </span>
                    )}
                    <span className="participants-label hidden-mobile">Roll Call</span>&nbsp;
                    <span
                        className="participants-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Participants"
                        data-container="body"
                        onClick={() => setModalVisible(true)}
                    >
                        <Users />
                    </span>
                    &nbsp;&nbsp;
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function WrapperComponents(props) {
    // values from autologin
    const jitsiContainer = 'jitsi-container';
    const widthViewport = document.getElementsByTagName('body')[0].offsetWidth;
    const isMobile = widthViewport <= 600;

    const {
        initName,
        initLanguage,
        initGender,
        conferenceName,
        modalVisible,
        setModalVisible,
        modalClipboard,
        setModalClipboard,
        isModerator,
        setIsModerator,
        userInvite,
        apiToken,
        modalLanguageDetection,
        setModalLanguageDetection,
        languages,
    } = props;
    const [layout, setLayout] = useState(2);
    const [jitsi, setJitsi] = useState({});
    const [oldSubtitles, setOldSubtitles] = useState([]);
    const [fontSizeSubtitles, setFontSizeSubtitles] = useState(2);
    const refSubtitles = useRef(null);
    const [{ subtitles: subtitlesComponent }] = useStateValue();
    const [isBuildingPdf, setIsBuildingPdf] = useState(false);
    const [modalDownload, setModalDownload] = useState(false);
    const [languageDetectionEnabled, setLanguageDetectionEnabled] = useState(false);
    const [valueLanguageDetection, setValueLanguageDetection] = useState(null);

    const emptyJitsi = Object.keys(jitsi).length === 0 && jitsi.constructor === Object;
    useEffect(() => {
        handleAudioVideo();
        if (!emptyJitsi) {
            return () => jitsi.dispose();
        }
    }, []);

    const isSpecialLanguage = (subtitles) => {
        const specialSubtitle = specialLanguages.find((special) => {
            var regex = new RegExp(special, 'g');
            var atLeastOneMatches = subtitles.some((e) => regex.test(e.speakerLanguage));
            return atLeastOneMatches;
        });

        return !!specialSubtitle;
    };

    const downloadSubtitlesPDF = (subtitles) => {
        setIsBuildingPdf(true);
        const languageBilingual = subtitlesComponent.sourceLanguage.value;
        const contentPDF = buildSubtitlesFile(subtitles, languageBilingual);

        // detect special languages
        const subtitlesWithSpecialLanguages = isSpecialLanguage(subtitles);

        // download unicode typography
        if (subtitlesWithSpecialLanguages) {
            window.pdfMake.fonts = {
                ArialUnicode: {
                    normal: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    bold: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    italics: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                },
            };
        }

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                font: subtitlesWithSpecialLanguages ? 'ArialUnicode' : 'Roboto',
                fontSize: 12,
            },
        };

        window.pdfMake.createPdf(docDefinition).download(() => {
            setIsBuildingPdf(false);
        });
    };

    const fetchTextToSpeechCustom = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
        try {
            let response = await fetch(endpoint.url.replace(/:sourceLanguage/g, sourceLanguage), {
                method: endpoint.method,
                redirect: 'follow',
                body: JSON.stringify({ text }),
            });
            let data = await response.json();
            try {
                var arrayBuffer = _base64ToArrayBuffer(data.audio);
                let blob = new Blob([arrayBuffer], { type: 'audio/wav' });
                const downloadUrl = window.webkitURL.createObjectURL(blob);
                return { downloadUrl, indexCall: itemTtsCall };
            } catch (e) {
                return {
                    indexCall: itemTtsCall,
                    error: e,
                };
            }
        } catch (err) {
            return {
                indexCall: itemTtsCall,
                error: err,
            };
        }
    };

    const _base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const initialiseJitsi = async () => {
        const _jitsi = new window.JitsiMeetExternalAPI('curiosity.worldwidetechconnections.com', {
            parentNode: document.getElementById(jitsiContainer),
            roomName: conferenceName,
            userInfo: {
                displayName: initName,
            },
            configOverwrite: {
                enableWelcomePage: false,
                enableClosePage: true,
                startWithAudioMuted: false,
                startWithVideoMuted: false,
                hideConferenceSubject: true,
                hideConferenceTimer: true,
            },
            interfaceConfigOverwrite: {
                TOOLBAR_BUTTONS: [
                    ...(userInvite ? [] : ['security', 'recording']),
                    'microphone',
                    'camera',
                    'closedcaptions',
                    'desktop',
                    'embedmeeting',
                    'fullscreen',
                    'highlight',
                    'participants-pane',
                    'videobackgroundblur',
                    'select-background',
                    'sharedvideo',
                    'shareaudio',
                    'fodeviceselection',
                    'hangup',
                    'profile',
                    'stats',
                    'etherpad',
                    'settings',
                    'raisehand',
                    'videoquality',
                    'filmstrip',
                    'download',
                    'help',
                    'mute-everyone',
                    'tileview',
                ],
            },
        });

        _jitsi.addEventListener('participantLeft', (val) => console.log(val));
        /*_jitsi.addEventListener('screenSharingStatusChanged', (obj) => {
            if (obj.on) {
                const baseUrl = window.location.origin;
                const url = `${baseUrl}/chat?n=${initName}-screenshare&id=${conferenceName}&lng=${initLanguage}&g=${initGender}`;
                window.open(
                    url,
                    '_blank',
                    `toolbar=0,location=0,menubar=0,left=5000px,width=480,height=${window.innerHeight}`,
                );
            }
        });*/
        _jitsi.addEventListener('readyToClose', () => {
            setOldSubtitles(refSubtitles.current.state.subtitles);
            const container = document.getElementById(jitsiContainer);
            container.removeChild(container.firstChild);
            setJitsi({});
            if (!isMobile) {
                setLayout(2);
            }
        });

        _jitsi.addEventListener('participantRoleChanged', (data) => {
            if (data.role === 'moderator') {
                setIsModerator(true);
            } else {
                setIsModerator(false);
            }
        });

        setJitsi(_jitsi);
    };

    const handleLayout = () => {
        setOldSubtitles(refSubtitles.current.state.subtitles);
        if (layout === 1) {
            setFontSizeSubtitles(2);
            return setLayout(2);
        }
        setFontSizeSubtitles(1);
        setLayout(1);
    };

    const handleAudioVideo = async () => {
        setOldSubtitles(refSubtitles.current.state.subtitles);
        if (layout === 2) {
            setFontSizeSubtitles(1);
            await setLayout(1);
        }
        initialiseJitsi();
    };

    const deleteParticipantsContent = async () => {
        await pubnub.signal({
            message: 'clean_history_content',
            channel: conferenceName,
        });
    };

    const renderSubtitles = () => {
        return (
            <Subtitles.SubtitlesUI
                ref={refSubtitles}
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage} //change this to spanish-international when you want to translate to spanish by default
                initialVisible={true}
                endpoint={signedEndpoint(apiToken, endpointTranslate)}
                provider="pubnub"
                speechRecognition={true}
                ttsOption={true}
                showButtonCloseComponent={false}
                showLanguageServices={true}
                channel={conferenceName}
                defaultBilingual={true}
                showFlipButton={true}
                style={
                    layout === 1
                        ? stylesWrapper.subtitlesContainer
                        : stylesWrapper.fullSubtitlesContainer
                }
                preRequestSubtitles={handlePreRequestSubtitles}
                render={(subtitles) => (
                    <div style={{ display: 'flex' }}>
                        <button
                            className="a-element"
                            onClick={() => downloadSubtitlesPDF(subtitles)}
                        >
                            <Download />
                        </button>
                        {emptyJitsi ? (
                            <button className="a-element" onClick={handleLayout}>
                                <Movie />
                            </button>
                        ) : null}
                    </div>
                )}
                subtitlesParent={oldSubtitles}
                fontSizeSubtitles={fontSizeSubtitles}
            />
        );
    };

    const renderJitsiFrame = () => {
        return (
            <div
                id="jitsi-container"
                style={
                    layout === 1
                        ? stylesWrapper.fullSubtitlesContainer
                        : stylesWrapper.subtitlesContainer
                }
            >
                {emptyJitsi ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <button
                            className="Button Button-primary"
                            onClick={handleAudioVideo}
                            style={{ padding: '0.9em 1em' }}
                        >
                            <div style={{ display: 'block', marginBottom: '.5em' }}>
                                <Desktop />
                            </div>
                            <p style={{ marginBottom: 0, textTransform: 'capitalize' }}>
                                enable audio/video
                            </p>
                        </button>
                    </div>
                ) : null}
            </div>
        );
    };

    const kickOutParticipant = async (participant) => {
        await pubnub.signal({
            message: `kickout_participant-${participant.id}`,
            channel: conferenceName,
        });
    };

    const subtitlesRendered = renderSubtitles();
    return (
        <div className="wrapper-components">
            <div className="wrapper-left">
                <div className="loading-jitsi">
                    <p>Loading audio/video...</p>
                </div>
                {layout === 1 ? renderJitsiFrame() : subtitlesRendered}
            </div>
            <div className="wrapper-right">
                <Chat.ChatUI
                    attachFile={true}
                    channel={conferenceName}
                    endpoint={signedEndpoint(apiToken, endpointTranslate)}
                    fontSizeChat={1}
                    initialSourceLanguage={initLanguage}
                    initialTargetLanguage={initLanguage}
                    initialVisible={true}
                    provider="pubnub"
                    style={stylesWrapper.chatContainer}
                    showButtonCloseComponent={false}
                    speechRecognition={true}
                    username={initName}
                />
                {layout === 1 ? subtitlesRendered : renderJitsiFrame()}
            </div>
            <SpeechToText.SpeechToTextUI
                username={initName}
                initialLanguage={initLanguage}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only speech recognition
                endpoint={signedEndpoint(apiToken, endpointSpeechToText)}
                detectLanguage={languageDetectionEnabled}
                endpointDetectLanguage={signedEndpoint(apiToken, endpointDetectLanguage)}
                paramsDetectLanguage={[initLanguage, valueLanguageDetection?.value]}
            />
            <TextToSpeech.TextToSpeechUI
                initialVendor="readspeaker"
                initialLanguage={initLanguage}
                initialGender={initGender}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only audio player
                endpoint={signedEndpoint(apiToken, endpointTextToSpeech)}
                fetchCustom={fetchTextToSpeechCustom}
            />
            {modalVisible ? (
                <ModalParticipants
                    closeModal={() => setModalVisible(false)}
                    pubnub={pubnub}
                    conferenceName={conferenceName}
                    kickOutParticipant={kickOutParticipant}
                    deleteParticipantsContent={deleteParticipantsContent}
                    isModerator={isModerator}
                />
            ) : null}
            {modalClipboard ? (
                <ModalClipboard
                    closeModal={() => setModalClipboard(false)}
                    pubnub={pubnub}
                    conferenceName={conferenceName}
                />
            ) : null}
            {isBuildingPdf ? <ModalDownload onCloseModal={() => setModalDownload(false)} /> : null}
            {modalLanguageDetection ? (
                <ModalLanguageDetection
                    closeModal={() => setModalLanguageDetection(false)}
                    pubnub={pubnub}
                    conferenceName={conferenceName}
                    languages={languages}
                    kickOutParticipant={kickOutParticipant}
                    deleteParticipantsContent={deleteParticipantsContent}
                    languageDetectionEnabled={languageDetectionEnabled}
                    setLanguageDetectionEnabled={setLanguageDetectionEnabled}
                    valueLanguageDetection={valueLanguageDetection}
                    setValueLanguageDetection={setValueLanguageDetection}
                />
            ) : null}
        </div>
    );
}

const ModalParticipants = (props) => {
    const {
        closeModal,
        pubnub,
        conferenceName,
        kickOutParticipant,
        deleteParticipantsContent,
        isModerator,
    } = props;
    const [participants, setParticipants] = useState([]);
    const myPubnubId = pubnub.userId;

    useEffect(() => {
        pubnub
            .hereNow({
                channels: [conferenceName],
                includeState: true,
            })
            .then(async (res) => {
                let participants = [];
                const participantsInChannel = res.channels[conferenceName].occupants;

                for (let index = 0; index < participantsInChannel.length; index++) {
                    const item = participantsInChannel[index];
                    try {
                        const { data } = await pubnub.objects.getUUIDMetadata({
                            uuid: item.uuid,
                        });
                        participants.push(data);
                    } catch (e) {
                        console.log(e);
                    }
                }
                setParticipants(participants);
            });
    }, []);

    const handleParticipantKickOut = (participant) => {
        kickOutParticipant(participant);
        const filteredParticipants = participants.filter((e) => e.id !== participant.id);

        setParticipants(filteredParticipants);
    };

    const downloadParticipants = () => {
        var contentPDF = [];
        contentPDF.push({
            text: `${conferenceName} - Roll Call`,
            style: 'header',
            bold: true,
        });
        participants.forEach((item, index) => {
            contentPDF.push({
                ul: [{ text: item.name, bold: false }],
            });
        });

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                fontSize: 12,
            },
        };
        window.pdfMake.createPdf(docDefinition).download('rollCallWWTC.pdf');
    };

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={stylesWrapper.wrapperModal}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Roll Call</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={downloadParticipants}>
                            <Download />
                        </button>
                        <button className="a-element" onClick={closeModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div className="body-container" style={stylesWrapper.bodyModal}>
                    {participants.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={stylesWrapper.participantItem}
                                className="participant-item"
                            >
                                <div style={stylesWrapper.descriptionParticipant}>
                                    <span>
                                        <User />
                                    </span>
                                    &nbsp;&nbsp;
                                    <p style={{ margin: 0, textIndent: 0 }}>
                                        {item.name}
                                        {myPubnubId === item.id ? ' (You)' : null}
                                    </p>
                                </div>
                                <div>
                                    {myPubnubId !== item.id ? (
                                        <button
                                            className="a-element"
                                            onClick={() => handleParticipantKickOut(item)}
                                        >
                                            <Times />
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <hr />
                {isModerator && (
                    <div
                        className="footer-container d-flex justify-content-center align-items-center"
                        styles={stylesWrapper.footerModal}
                    >
                        <button
                            className=" btn btn-success btn-sm"
                            disabled={participants.length === 1}
                            onClick={deleteParticipantsContent}
                        >
                            Delete participants content
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const ModalLanguageDetection = (props) => {
    const {
        closeModal,
        languages,
        languageDetectionEnabled,
        setLanguageDetectionEnabled,
        valueLanguageDetection,
        setValueLanguageDetection,
    } = props;

    const handleOkModal = () => {
        if (!!languageDetectionEnabled && !valueLanguageDetection) {
            alert('Please, select your secondary language');
            return;
        }

        if (!languageDetectionEnabled) {
            setValueLanguageDetection(null);
            closeModal();
        }

        if (!!languageDetectionEnabled && !!valueLanguageDetection) {
            closeModal();
        }
    };

    const handleCloseModal = () => {
        if (!!languageDetectionEnabled && !valueLanguageDetection) {
            setValueLanguageDetection(null);
            setLanguageDetectionEnabled(false);
            closeModal();
            return;
        }

        if (!languageDetectionEnabled) {
            setValueLanguageDetection(null);
            closeModal();
        }

        if (!!languageDetectionEnabled && !!valueLanguageDetection) {
            closeModal();
        }
    };

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 250 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span style={{ fontSize: 14 }}>Language Detection</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={handleCloseModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div className="body-container" style={stylesWrapper.bodyModal}>
                    <br />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <input
                                type="radio"
                                id="disabled"
                                name="languageDetectionEnabled"
                                value="disabled"
                                checked={languageDetectionEnabled === false}
                                onChange={(e) => setLanguageDetectionEnabled(false)}
                            />
                            <label htmlFor="disabled" style={{ fontSize: 14 }}>
                                Disabled
                            </label>
                        </div>

                        <div>
                            <input
                                type="radio"
                                id="enabled"
                                name="languageDetectionEnabled"
                                value="enabled"
                                checked={languageDetectionEnabled === true}
                                onChange={(e) => setLanguageDetectionEnabled(true)}
                            />
                            <label htmlFor="enabled" style={{ fontSize: 14 }}>
                                Enabled
                            </label>
                        </div>
                    </div>
                    <br />
                    {languageDetectionEnabled && (
                        <div>
                            <p style={{ fontSize: 14 }}>Select your secondary language</p>
                            <Select
                                handleChange={(lng) => setValueLanguageDetection(lng)}
                                placeholder="Select language"
                                data={languages}
                                value={valueLanguageDetection || ''}
                                showLanguageServices={true}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    )}
                </div>
                <hr />
                <div
                    className="footer-container d-flex justify-content-center align-items-center"
                    styles={stylesWrapper.footerModal}
                >
                    <button onClick={handleOkModal} className="btn btn-success btn-sm">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );
};

const ModalClipboard = (props) => {
    const { closeModal, pubnub, conferenceName } = props;
    const [isCopied, setIsCopied] = useState(false);

    const buildInvitationLink = () => {
        const baseUrl = window.location.href.split('/app')[0];
        const hostId = pubnub.getUUID();
        const url = `${baseUrl}/invitation?room=${conferenceName}&hostId=${hostId}`;
        return url;
    };

    const copyLinkToClipboard = () => {
        // Create a "hidden" input
        var aux = document.createElement('input');
        var url = buildInvitationLink();

        // Assign it the value of the specified element
        aux.setAttribute('value', url);

        // Append it to the body
        document.body.appendChild(aux);

        // Highlight its content
        aux.select();

        // Copy the highlighted text
        document.execCommand('copy');

        // Remove it from the body
        document.body.removeChild(aux);

        setIsCopied(true);
    };

    const link = buildInvitationLink();

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 250, width: 450 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Share room</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={closeModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div
                    className="body-container"
                    style={{ ...stylesWrapper.bodyModalInvite, textAlign: 'center' }}
                >
                    <p>Invitation link</p>
                    <p style={{ fontSize: 14 }}>{link}</p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 48,
                        }}
                    >
                        <button
                            type="button"
                            className="Button Button-small Button-primary"
                            onClick={copyLinkToClipboard}
                        >
                            {isCopied ? 'Copied' : 'Copy to clipboard'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ModalDownload = ({ onCloseModal }) => {
    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 180, width: 450 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>PDF Subtitles</span>
                    </div>
                    <div className="header-options"></div>
                </div>
                <div
                    className="body-container"
                    style={{ ...stylesWrapper.bodyModal, textAlign: 'center' }}
                >
                    <p>Downloading PDF file...</p>
                    <p style={{ fontSize: 14 }}>Please wait, this could take a few seconds.</p>
                </div>
            </div>
        </div>
    );
};

const stylesWrapper = {
    fullSubtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        margin: '5px auto',
        position: 'relative',
        zIndex: 20,
    },
    subtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    chatContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    containerModal: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 50px)',
        width: '100vw',
        background: 'rgba(1,1,1,0.3)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 2000,
    },
    bodyModal: {
        marginTop: 24,
        height: 'calc(100% - 90px)',
        overflow: 'auto',
    },
    bodyModalInvite: {
        marginTop: 24,
        height: 'calc(100% - 30px)',
        overflow: 'auto',
    },
    wrapperModal: {
        padding: '10px',
        backgroundColor: 'white',
        borderRadius: 5,
        height: 500,
        top: 'calc(50% - 250px)',
        width: 320,
        position: 'absolute',
        WebkitBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        MozBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        boxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
    },
    participantItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 1em',
        cursor: 'default',
    },
    descriptionParticipant: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export default Main;
